import React, { useState, useContext } from "react";
import logo from "../images/mistypathhorizwithtagline.png";
import { Link, NavLink } from "react-router-dom";
import searchButton from "../images/search.png";
import UserContext from "./UserContext";

const Navbar = ({ onSearch }) => {
  //This is a state declaration. We use this instead of a regular declaration because React needs
  //to know when searchTerm has changed.  If we were to just set search term equal to something,
  //React would not be aware and not rerender the page.  By using setSearchTerm to set the value
  //of searchTerm,  react knows that the searchterm value has changed.  It can then use the changed
  //value when conducting the search
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useContext(UserContext);

  //works in conjunction with the onKeyDown listener to send the search term back to the parent when enter is hit
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(searchTerm); // Pass search term to parent (App.js)
    }
  };

  // Update search term state on input change
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <nav className="navbar">
      <div className="left-content">
        <Link to="/">
          <img src={logo} alt="Horizontal Logo" className="logo" />
        </Link>
      </div>

      <div className="nav-right">
        <div>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "nav-button highlighted" : "nav-button"
            }
          >
            home
          </NavLink>
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "nav-button highlighted" : "nav-button"
            }
          >
            dashboard
          </NavLink>

          <NavLink
            to="/recommender"
            className={({ isActive }) =>
              isActive ? "nav-button highlighted" : "nav-button"
            }
          >
            recommender
          </NavLink>
          {/* Conditional rendering of login or "My Account" based on user login status */}
          {user ? (
            <NavLink
              to="/myaccount"
              className={({ isActive }) =>
                isActive ? "nav-button highlighted" : "nav-button"
              }
            >
              my account
            </NavLink>
          ) : (
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive ? "nav-button highlighted" : "nav-button"
              }
            >
              login
            </NavLink>
          )}
        </div>

        <div className="search-container">
          <input
            className="searchbar"
            type="text"
            value={searchTerm}
            onChange={handleChange} // updates the searchTerm via setSearchTerm every time a key is pressed.
            onKeyDown={handleKeyPress} //this is listening for every keypress and seeing if it's enter.  If so, it runs handleKeyPress
            //and uses the value that has been continuously updated in the onChange event
            placeholder="Type any game name"
          />
          <img
            src={searchButton}
            onClick={() => onSearch(searchTerm)}
            alt="searchButton"
            className="search-button"
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
