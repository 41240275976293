import React from "react";

// Create a new context variable so that user can be shared throughout
// the components of the app. Login page will call this function and
// set the user context to the user object that is

const UserContext = React.createContext({
  user: null, // Initially, no user is logged in
  setUser: () => {}, // This function will be used to update the user
});

export default UserContext;
