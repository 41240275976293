import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import GameContext from "./GameContext";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import noImage from "../images/no_image_avail.JPG";

const DisplayGames = ({ games }) => {
  console.log(games);

  const { setGame } = useContext(GameContext);
  const navigate = useNavigate();
  const handleCardClick = (game) => {
    setGame(game);
    navigate("/game");
  };

  if (!games.length) {
    return <p>No games to display.</p>;
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      gap={2}
      sx={{ marginLeft: "50px" }}
    >
      <>
        {games.map((game) => (
          <Card
            key={game.id}
            sx={{
              width: "440px",
              height: "310px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              fontFamily: "Open Sans",
            }}
          >
            <CardActionArea onClick={() => handleCardClick(game)}>
              <CardMedia
                component="img"
                height="140"
                image={game.image ? game.image : noImage}
                alt={game.name}
                sx={{ objectFit: "cover" }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{ fontFamily: "Open Sans", fontSize: "20px" }}
                >
                  {game.name ? game.name : game.gameName}
                </Typography>
                <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                  <Grid container spacing="2px" marginTop={"10px"}>
                    {game.gameRecommenderScore !== null &&
                    game.gameRecommenderScore !== undefined ? (
                      <>
                        <Grid size={7}>Recommender Value:</Grid>
                        <Grid size={5}>
                          {Math.round(game.gameRecommenderScore)}
                        </Grid>
                        <Grid size={7}>Critic Score:</Grid>
                        <Grid size={5}>{game.metacritic}</Grid>
                      </>
                    ) : (
                      ""
                    )}
                    {game.gameRecommenderScore === null ||
                    game.gameRecommenderScore === undefined ? (
                      <>
                        <Grid size={5}>Critic Score:</Grid>
                        <Grid size={7}>
                          {game.metacritic ? game.metacritic : "No Reviews"}
                        </Grid>
                        <Grid size={5}>Release Date:</Grid>
                        <Grid size={7}>
                          {new Date(game.release_date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            },
                          )}{" "}
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </>
    </Box>
  );
};

export default DisplayGames;
