import "../App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import UserContext from "./UserContext";
import GameContext from "./GameContext";

//Imports all child components for use in React Router
import Home from "./Home";
import Search from "./Search";
import Navbar from "./Navbar";
import About from "./About";
import Contact from "./Contact";
import Login from "./Login";
import Create from "./Create";
import Dashboard from "./Dashboard";
import Faq from "./Faq";
import Recommender from "./Recommender";
import Footer from "./Footer";
import MyAccount from "./MyAccount";
import Game from "./Game";

function App() {
  // *******************SEARCH IS STILL A WORK IN PROGRESS *************************

  // Function to handle search and navigate to the search page
  // This is needed here because navbar and search are both children of
  // App.js. So the search term was in the navbar component and could not
  // be accessed by any sibling components (search) unless it was passed to the parent
  // the parent then uses the searchterm to navigate to the search via the route path
  // which can then search and update

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    navigate("/search");
  };

  const navigate = useNavigate();
  // *******************************************************************************

  const [user, setUser] = useState(null);
  const [game, setGame] = useState(null);

  return (
    // the first line below makes sure that all components can 1) access user object and 2) set the user as logged in or not
    <GameContext.Provider value={{ game, setGame }}>
      <UserContext.Provider value={{ user, setUser }}>
        <div className="page">
          <Navbar onSearch={handleSearch} />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/search"
                element={<Search searchTerm={searchTerm} />}
              />
              <Route path="/about" element={<About />} />
              {/* <Route path="/game" element={<Game />} /> */}
              <Route
                path="/game"
                // If the user is not logged in and tries to access the dashboard, this navigates them to login
                element={user ? <Game /> : <Navigate to="/login" />}
              />

              <Route path="/contact" element={<Contact />} />
              <Route
                path="/login"
                // The following line checks to see if the user is logged in if so automatically navigates to Dashboard
                element={user ? <Navigate to="/dashboard" /> : <Login />}
              />
              <Route
                path="/dashboard"
                // If the user is not logged in and tries to access the dashboard, this navigates them to login
                element={user ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route path="/faq" element={<Faq />} />
              {/* <Route path="/recommender" element={<Recommender />} /> */}
              <Route
                path="/recommender"
                // If the user is not logged in and tries to access the dashboard, this navigates them to login
                element={user ? <Recommender /> : <Navigate to="/login" />}
              />
              <Route path="/create" element={<Create />} />
              <Route
                path="/myaccount"
                // Even though it's not a button until they've logged in, I put this here in case they try to access the
                // myaccount path via the browser
                element={user ? <MyAccount /> : <Navigate to="/login" />}
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </UserContext.Provider>
    </GameContext.Provider>
  );
}

export default App;
