import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import gameController from "../images/icon_game_controller_.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React, { useState, useContext, useEffect } from "react";
import DOMPurify from "dompurify";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate, NavLink } from "react-router-dom";
import UserContext from "./UserContext";
import { SERVER_ADDRESS } from "./config";

//const serverAddress = "http://loadbalancervgproject-11834139.us-east-2.elb.amazonaws.com:4000";
//const serverAddress = "http://localhost:4000";

const Create = () => {
  const { user, setUser } = useContext(UserContext);

  console.log(user);

  useEffect(() => {
    if (user && user.username) {
      setEmail(user.username);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setFavGenres(user.favGenres);
      setFavSystems(user.favSystems);
      setPhoneNumber(user.phoneNumber);
      setBirthYear(user.birthYear);
      setContactChoices(user.contactChoices);
    }
  }, [user]);

  // create multiple useState hooks for use throughout this form
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [birthYear, setBirthYear] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorPassword1, setErrorPassword1] = useState("");
  const [errorPassword2, setErrorPassword2] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorSystems, setErrorSystems] = useState("");
  const [errorGenre, setErrorGenre] = useState("");
  const [errorBirthYear, setErrorBirthYear] = useState("");

  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [favSystems, setFavSystems] = useState([]);
  const [contactChoices, setContactChoices] = useState([]);
  const [favGenres, setFavGenres] = useState([]);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    const isValid = /^\(\d{3}\) \d{3}-\d{4}$/.test(e.target.value);
    setPhoneError(!isValid);
  };

  const navigate = useNavigate();

  // ************************* validateSignUp Helper Function *************************
  const validateSignUp = async (event) => {
    event.preventDefault();

    // Sets initial error messages to blank
    setErrorMessageEmail("");
    setErrorPassword1("");
    setErrorPassword2("");
    setErrorFirstName("");
    setErrorLastName("");
    setErrorSystems("");
    setErrorGenre("");
    setErrorBirthYear("");

    //Defines the special characters to check for (ensuring that the user included one)
    const specialCharacters = /[!@#$%&*]/;

    // Initially isCorrect to true and it will change to false if any of the validation criteria is not met
    let isCorrect = true;
    console.log("Is correct phone" + isCorrect);
    // Validate phone number
    if (!phoneNumber || phoneError) {
      setPhoneError(true);
      isCorrect = false;
    }

    // Starting strings for error messages
    let passwordReq1 = "Password must: ";
    let passwordReq2 = "Password must: ";

    if (!user) {
      // Ensuring that email address is valid
      // Regular expression pattern from https://www.w3resource.com/javascript/form/email-validation.php
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setErrorMessageEmail("");
      } else {
        setErrorMessageEmail("Please enter valid email");
        isCorrect = false;
      }

      // Ensures that first password length is at least 10 characters
      if (password1.length < 10) {
        passwordReq1 = passwordReq1 + "be at least 10 characters";
        passwordReq1 = passwordReq1 + ", ";
        isCorrect = false;
      }

      // Ensures that first password length no more than 20 characters
      if (password1.length > 20) {
        passwordReq1 = passwordReq1 + "be no more than 20 characters";
        passwordReq1 = passwordReq1 + ", ";
        isCorrect = false;
      }

      // Ensures that first password length includes a capital letter
      if (!/[A-Z]/.test(password1)) {
        passwordReq1 = passwordReq1 + "include a capital letter";
        passwordReq1 = passwordReq1 + ", ";
        isCorrect = false;
      }

      // Ensures that first password length includes a lowercase
      if (!/[a-z]/.test(password1)) {
        passwordReq1 = passwordReq1 + "include a lowercase letter";
        passwordReq1 = passwordReq1 + ", ";
        isCorrect = false;
      }

      // Ensures that first password length includes at least one number
      if (!/\d/.test(password1)) {
        passwordReq1 = passwordReq1 + "include at least one number";
        passwordReq1 = passwordReq1 + ", ";
        isCorrect = false;
      }

      // Ensures that first password length includes at lesat one special character
      if (!specialCharacters.test(password1)) {
        passwordReq1 = passwordReq1 + "include one special character";
        passwordReq1 = passwordReq1 + ", ";
        isCorrect = false;
      }

      // Repeats checks for password 2 - Note that this will be coded into a helper function in the future
      // to avoid duplication for code (i.e. for password 1 and 2)
      if (password2.length < 10) {
        passwordReq2 = passwordReq2 + "be at least 10 characters";
        passwordReq2 = passwordReq2 + ", ";
        isCorrect = false;
      }

      if (password2.length > 20) {
        passwordReq2 = passwordReq2 + "be no more than 20 characters";
        passwordReq2 = passwordReq2 + ", ";
        isCorrect = false;
      }

      if (!/[A-Z]/.test(password2)) {
        passwordReq2 = passwordReq2 + "include a capital letter";
        passwordReq2 = passwordReq2 + ", ";
        isCorrect = false;
      }

      if (!/[a-z]/.test(password2)) {
        passwordReq2 = passwordReq2 + "include a lowercase letter";
        passwordReq2 = passwordReq2 + ", ";
        isCorrect = false;
      }

      if (!/\d/.test(password2)) {
        passwordReq2 = passwordReq2 + "include at least one number";
        passwordReq2 = passwordReq2 + ", ";
        isCorrect = false;
      }

      if (!specialCharacters.test(password2)) {
        passwordReq2 = passwordReq2 + "include one special character";
        passwordReq2 = passwordReq2 + ", ";
        isCorrect = false;
      }

      setErrorPassword1(passwordReq1);
      setErrorPassword2(passwordReq2);

      if (passwordReq1 === "Password must: ") {
        passwordReq1 = "";
        setErrorPassword1(passwordReq1);
      }

      if (passwordReq2 === "Password must: ") {
        passwordReq2 = "";
        setErrorPassword2(passwordReq2);
      }

      // Ensures that password matches reentered password
      if (password1 !== password2) {
        setErrorPassword1("Passwords Do Not Match Each Other");
        setErrorPassword2("Passwords Do Not Match Each Other");
        isCorrect = false;
      }
    }

    console.log("Is correct password" + isCorrect + password1 + password2);
    // Ensures first name is entered
    if (firstName.length === 0) {
      setErrorFirstName("Please enter a first name");
      isCorrect = false;
    }

    // Ensures first name no more than 30 characters
    if (firstName.length > 30) {
      setErrorFirstName("First name can be no more than 30 characters");
      isCorrect = false;
    }

    // Ensures last name is entered
    if (lastName.length === 0) {
      setErrorLastName("Please enter a last name");
      isCorrect = false;
    }

    // Ensures last name is no more than 30 characters
    if (lastName.length > 30) {
      setErrorLastName("First name can be no more than 30 characters");
      isCorrect = false;
    }

    // Ensures at least one favorite system is checked
    if (favSystems.length === 0) {
      setErrorSystems("Please choose at least one");
      isCorrect = false;
    }

    // Ensures at least one favorite genre is checked
    if (favGenres.length === 0) {
      setErrorGenre("Please choose at least one");
      isCorrect = false;
    }

    // Ensures birth year is integer
    if (!Number.isInteger(Number(birthYear.trim()))) {
      console.log(birthYear);
      setErrorBirthYear("Birth year must be an integer");
      isCorrect = false;
    }

    // Ensures birth date is valid
    if (new Date().getFullYear() - birthYear < 18) {
      setErrorBirthYear("You must be at least 18 to use this site");
      isCorrect = false;
    }

    // Ensures birth date is valid
    if (new Date().getFullYear() - birthYear > 110) {
      setErrorBirthYear("Please enter valid birth year");
      isCorrect = false;
    }

    // If validation is met, then we santize the inputs via dompurify
    console.log("Is correct: " + isCorrect);
    if (isCorrect) {
      const sanitizedEmailInput = DOMPurify.sanitize(email);
      console.log("Email Address: " + sanitizedEmailInput);

      const sanitizedPassword1 = DOMPurify.sanitize(password1);
      console.log("Password: " + sanitizedPassword1);

      const sanitizedPassword2 = DOMPurify.sanitize(password2);
      console.log("Re-entered Password: " + sanitizedPassword2);

      const sanitizedFirstName = DOMPurify.sanitize(firstName);
      console.log("First Name: " + sanitizedFirstName);

      const sanitizedLastName = DOMPurify.sanitize(lastName);
      console.log("Last Name: " + sanitizedLastName);

      console.log("Favorite Systems: " + favSystems);
      console.log("Contact Choices: " + contactChoices);
      console.log("Favorite Genres: " + favGenres);

      if (!user) {
        try {
          console.log("MADE IT!!!");
          const response = await axios.post(
            `${SERVER_ADDRESS}/api/user/register`,
            {
              username: sanitizedEmailInput,
              password: sanitizedPassword1,
              firstName: sanitizedFirstName,
              lastName: sanitizedLastName,
              favSystems,
              contactChoices,
              favGenres,
              phoneNumber,
              birthYear,
            },
          );

          console.log(response.data);
          alert("Account Created Successfully. Navigating to Login Page");
          navigate("/login");
        } catch (error) {
          const errorMsg = error.response?.data || "Error creating user";
          alert("Error creating account." + error.response?.data?.error);
          console.log(errorMsg);
        }
      }

      if (user) {
        try {
          await axios.put(
            `${SERVER_ADDRESS}/api/user/${user.username}/changeInfo/`,
            {
              firstName: sanitizedFirstName,
              lastName: sanitizedLastName,
              favSystems,
              contactChoices,
              favGenres,
              phoneNumber,
              birthYear,
            },
            { withCredentials: true },
          );
          const fullInfoResponse = await axios.get(
            `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
            {
              withCredentials: true,
            },
          );

          alert("Account Updated Successfully.");
          setUser(fullInfoResponse.data);
          navigate("/myaccount");
        } catch (error) {
          alert("Error updating account." + error.response?.data?.error);
        }
      }
    }
  };

  // Adds or removes checkbox value from favSystems if checkbox is checked or unchecked
  const handleFavSystemsChange = (event, system) => {
    if (event.target.checked) {
      setFavSystems((prevFavSystems) => [...prevFavSystems, system]);
    } else {
      setFavSystems((prevFavSystems) =>
        prevFavSystems.filter((item) => item !== system),
      );
    }
  };

  // Adds or removes checkbox value from contactChoices if checkbox is checked or unchecked
  const handleContactChange = (event, contact) => {
    if (event.target.checked) {
      setContactChoices((prevContactChoices) => [
        ...prevContactChoices,
        contact,
      ]);
    } else {
      setContactChoices((prevContactChoices) =>
        prevContactChoices.filter((item) => item !== contact),
      );
    }
  };

  // If user selects a favorite genre, this adds it into an array of genres in favGenres
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFavGenres(typeof value === "string" ? value.split(",") : value);
  };

  // Possible values for favorite genre
  const genres = [
    "Action",
    "Adventure",
    "Sidescroller",
    "Puzzle",
    "Role Playing",
    "Side Scroller",
    "Simulation",
  ];

  return (
    //Creates primary container for form (the bordered box with rounded edges)
    <div className="centered-create-container">
      <form onSubmit={validateSignUp}>
        <Card
          sx={{
            minWidth: 1130,
            maxWidth: 700,
            height: "auto",
            minHeight: 710,
            borderRadius: "20px",
            border: "2px solid black",
            paddingLeft: 0,
            paddingTop: 1,
            paddingRight: 10,
            position: "relative",
          }}
        >
          <Box
            display="flex"
            justifyContent="left"
            paddingLeft="30px"
            alignItems="center"
            marginBottom="24px"
          >
            <img
              src={gameController}
              alt="Game Controller"
              style={{ width: 59, height: 43, marginRight: 15 }}
            />
            <Typography
              sx={{
                fontSize: "36px",
                color: "#3000ff",
                fontWeight: "bold",
                fontFamily: "Open Sans",
              }}
              component="div"
            >
              {user ? "Update Account Information" : "Create an Account"}
            </Typography>
          </Box>

          {/* Defines the overall layout of the grid that holds the 3 columns */}
          <CardContent
            sx={{
              display: "grid",
              gridTemplateColumns: "450px auto 300px",
              gridGap: "20px",
              height: "100%",
            }}
          >
            {/* First column for email, passwords, and name */}
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginLeft="20px"
              gap="12px"
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000000",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "20px",
                }}
                component="div"
              >
                {user ? "Login Information" : "Create Login Information"}
              </Typography>

              {/* Email address textfield and error message */}
              <TextField
                required
                id="filled-required-email-address"
                label="Email Address"
                variant="filled"
                value={email}
                disabled={user}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                sx={{
                  minWidth: "400px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  border: "2px solid black",
                  input: { color: "black" },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "14px",
                }}
                id="errorEmail"
                component="div"
              >
                {errorMessageEmail}
              </Typography>

              {/* First password textfield and error message */}
              <TextField
                required
                id="passwordOriginal"
                label={user ? "" : "Password"}
                type="password"
                autoComplete="new-password"
                variant="filled"
                disabled={user}
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
                sx={{
                  minWidth: "400px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  border: "2px solid black",
                  input: { color: "black" },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "14px",
                }}
                id="errorPassword"
                component="div"
              >
                {errorPassword1}
              </Typography>

              {/* Second Password Textfield and Error Message */}
              <TextField
                required
                id="passwordConfirm"
                label={user ? "" : "Please Retype Your Password"}
                type="password"
                autoComplete="new-password"
                variant="filled"
                disabled={user}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                sx={{
                  minWidth: "400px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  border: "2px solid black",
                  input: { color: "black" },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "14px",
                }}
                component="div"
                id="errorConfirmPassword"
              >
                {errorPassword2}
              </Typography>

              {/* Password requirements */}
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "14px",
                }}
                component="div"
              >
                {user
                  ? ""
                  : "Password must at least 10 characters and contain capital and lowercase letters, a number, and a special character (!@#$%&*)"}
              </Typography>

              {/* First Name textfield and error message */}
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000000",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "20px",
                }}
                component="div"
              >
                What's Your Name?
              </Typography>
              <TextField
                required
                id="filled-required-first-name"
                label="First Name"
                variant="filled"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{
                  minWidth: "400px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  border: "2px solid black",
                  input: { color: "black" },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "14px",
                }}
                id="firstNameError"
                component="div"
              >
                {errorFirstName}
              </Typography>

              {/* Last name text field and error message */}

              <TextField
                required
                id="filled-required-last-name"
                label="Last Name"
                variant="filled"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  minWidth: "400px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  border: "2px solid black",
                  input: { color: "black" },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "14px",
                }}
                id="lastNameError"
                component="div"
              >
                {errorLastName}
              </Typography>
            </Box>

            {/* Middle Column of Grid which holds favorite system and contact */}
            <Box
              sx={{ flexBasis: "275px", flexShrink: 0, marginRight: "20px" }}
            >
              {/* Favorite Systems Checkbox */}
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000000",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                component="div"
              >
                Favorite Systems*
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#000000",
                  fontWeight: "normal",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                component="div"
              >
                (Check All That Apply)
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={favSystems.includes("Playstation 5")}
                      onChange={(e) =>
                        handleFavSystemsChange(e, "Playstation 5")
                      }
                    />
                  }
                  label="Playstation 5"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={favSystems.includes("Xbox Series X/S")}
                      onChange={(e) =>
                        handleFavSystemsChange(e, "Xbox Series X/S")
                      }
                    />
                  }
                  label="Xbox Series X/S"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={favSystems.includes("Nintendo Switch")}
                      onChange={(e) =>
                        handleFavSystemsChange(e, "Nintendo Switch")
                      }
                    />
                  }
                  label="Nintendo Switch"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={favSystems.includes("PC")}
                      onChange={(e) => handleFavSystemsChange(e, "PC")}
                    />
                  }
                  label="PC"
                />
              </FormGroup>
              <Typography
                id="errorNoSystems"
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                component="div"
              >
                {errorSystems}
              </Typography>
              {/* Contact methods checkbox and logic */}
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000000",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingTop: "50px",
                }}
                component="div"
              >
                How May We Contact You?
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#000000",
                  fontWeight: "normal",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                component="div"
              >
                (Check All That Apply)
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactChoices.includes(
                        "Newsletter Subscription",
                      )}
                      onChange={(e) =>
                        handleContactChange(e, "Newsletter Subscription")
                      }
                    />
                  }
                  label="Newsletter Subscription"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactChoices.includes("Site Feedback Surveys")}
                      onChange={(e) =>
                        handleContactChange(e, "Site Feedback Surveys")
                      }
                    />
                  }
                  label="Site Feedback Surveys"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactChoices.includes("Promotions/Giveaways")}
                      onChange={(e) =>
                        handleContactChange(e, "Promotions/Giveaways")
                      }
                    />
                  }
                  label="Promotions/Giveaways"
                />
              </FormGroup>
              <Box sx={{ marginTop: "0px" }}></Box>{" "}
            </Box>

            {/* Right box of grid, with favorite genres dropdown multi-select */}
            <Box sx={{ flexBasis: "275px", flexShrink: 0 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000000",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                }}
                component="div"
              >
                Favorite Genres*
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#000000",
                  fontWeight: "normal",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                component="div"
              >
                (You may select more than one)
              </Typography>

              <div>
                <FormControl sx={{ marginTop: 2, width: 300 }}>
                  <InputLabel>Genre</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={favGenres}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                  >
                    {genres.map((genre) => (
                      <MenuItem key={genre} value={genre}>
                        {genre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                id="errorNoGenres"
                component="div"
              >
                {errorGenre}
              </Typography>

              <InputMask
                mask="(999) 999-9999"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              >
                {(inputProps) => (
                  <TextField
                    sx={{ marginTop: 4, width: 300 }}
                    {...inputProps}
                    id="phoneNumber"
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    error={phoneError}
                    helperText={phoneError ? "Invalid phone number" : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </InputMask>

              <TextField
                required
                id="filled-required-birth-year"
                label="Birth-Year"
                variant="outlined"
                value={birthYear}
                onChange={(e) => setBirthYear(e.target.value)}
                sx={{
                  marginTop: 4,
                  minWidth: "300px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  input: { color: "black" },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  textAlign: "left",
                  alignSelf: "flex-start",
                  paddingLeft: "0px",
                }}
                id="birthYearError"
                component="div"
              >
                {errorBirthYear}
              </Typography>

              <Button
                sx={{
                  backgroundColor: "#3000ff",
                  fontSize: "24px",
                  fontFamily: "Open Sans",
                  minWidth: "310px",
                  height: "45px",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontWeight: "normal",
                  marginTop: "175px",
                }}
                id="createSubmitButton"
                variant="contained"
                onClick={validateSignUp}
              >
                {user ? "Update" : "Submit"}
              </Button>

              <Box
                sx={{
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "black",
                    fontFamily: "Open Sans",
                    textAlign: "left",
                    alignSelf: "flex-start",
                    paddingRight: "80px",
                  }}
                  component="div"
                >
                  * Required
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

export default Create;
