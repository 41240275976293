import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DisplayGames from "./DisplayGames";
import UserContext from "./UserContext";
import { SERVER_ADDRESS } from "./config";

const Recommender = () => {
  //const serverAddress = "http://loadbalancervgproject-11834139.us-east-2.elb.amazonaws.com:4000";
  //const serverAddress = "http://localhost:4000";
  const { user, setUser } = useContext(UserContext);

  const [recommendations, setRecommendations] = useState(null);

  useEffect(() => {
    if (user) {
      const fetchRecommendations = async () => {
        try {
          const response = await axios.get(
            `${SERVER_ADDRESS}/api/user/${user.username}/recommendations`,
            {
              withCredentials: true, // Add this to allow session cookies
            },
          );
          setRecommendations(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchRecommendations();
    }
  }, [user]);

  return (
    <div className="search-results">
      {recommendations ? (
        <div>
          <DisplayGames games={recommendations.slice(0, 25)} />
        </div>
      ) : (
        <p>No recommendations exist.</p>
      )}
    </div>
  );
};

export default Recommender;
