import React, { useState, useEffect } from "react";
import axios from "axios";
import DisplayGames from "./DisplayGames";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { SERVER_ADDRESS } from "./config";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

//const serverAddress = "http://loadbalancervgproject-11834139.us-east-2.elb.amazonaws.com:4000";
//const serverAddress = "http://localhost:4000";

const Search = ({ searchTerm }) => {
  const [searchResults, setSearchResults] = useState(null);
  const [dlcToggle, setDLCToggle] = useState("off");
  const [ratingToggle, setRatingToggle] = useState("all");
  const [systemToggle, setSystemToggle] = useState("all");
  const [sortToggle, setSortToggle] = useState("rating_desc");
  const [totalResults, setTotalResults] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (searchTerm) {
      const fetchSearchResults = async () => {
        try {
          const response = await axios.get(`${SERVER_ADDRESS}/api/searchIGDB`, {
            params: {
              search: searchTerm,
              dlcToggle: dlcToggle,
              ratingToggle: ratingToggle,
              systemToggle: systemToggle,
              sortToggle: sortToggle,
              limit: limit,
              offset: offset,
            },
          });
          setSearchResults(response.data.results);
          setTotalResults(response.data.totalResults);
          setTotalPages(Math.ceil(response.data.totalResults / limit));
        } catch (error) {
          console.error(error);
        }
      };

      fetchSearchResults();
    }
  }, [searchTerm, dlcToggle, ratingToggle, systemToggle, sortToggle, page]);

  const handleToggleChange = (event, newDlcToggle) => {
    if (newDlcToggle !== null) {
      setDLCToggle(newDlcToggle);
    }
  };

  const handleRatingChange = (event, newRatingToggle) => {
    if (newRatingToggle !== null) {
      setRatingToggle(newRatingToggle);
    }
  };

  const handleSystemChange = (event, newSystemToggle) => {
    if (newSystemToggle !== null) {
      setSystemToggle(newSystemToggle);
    }
  };

  const handleSortChange = (event, newSortToggle) => {
    if (newSortToggle !== null) {
      setSortToggle(newSortToggle);
    }
  };

  const handlePageChange = (event, value) => {
    setOffset((value - 1) * limit);
    setPage(value);
  };

  return (
    <div className="search-results">
      {searchResults ? (
        <div>
          <ToggleButtonGroup
            color="primary"
            value={dlcToggle}
            exclusive
            onChange={handleToggleChange}
            aria-label="Platform"
            sx={{
              fontFamily: "Open Sans",
              marginLeft: "50px",
              marginRight: "20px",
            }}
          >
            <ToggleButton
              value="off"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Games Only
            </ToggleButton>
            <ToggleButton
              value="on"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Games and DLC
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={ratingToggle}
            exclusive
            onChange={handleRatingChange}
            aria-label="Platform"
            sx={{ fontFamily: "Open Sans", marginRight: "20px" }}
          >
            <ToggleButton
              value="all"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              All Games
            </ToggleButton>
            <ToggleButton
              value="90 plus"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Rating 90+
            </ToggleButton>

            <ToggleButton
              value="80 plus"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Rating 80+
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={systemToggle}
            exclusive
            onChange={handleSystemChange}
            aria-label="Platform"
            sx={{ fontFamily: "Open Sans", marginRight: "20px" }}
          >
            <ToggleButton
              value="all"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              All Systems
            </ToggleButton>
            <ToggleButton
              value="ps5"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              PS5
            </ToggleButton>

            <ToggleButton
              value="switch"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Switch
            </ToggleButton>

            <ToggleButton
              value="xbox"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Xbox Series S/X
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={sortToggle}
            exclusive
            onChange={handleSortChange}
            aria-label="Platform"
            sx={{ fontFamily: "Open Sans" }}
          >
            <ToggleButton
              value="rating_desc"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Critic High to Low
            </ToggleButton>
            <ToggleButton
              value="rating_asc"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Critic Low to High
            </ToggleButton>

            <ToggleButton
              value="newestFirst"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Newest First
            </ToggleButton>

            <ToggleButton
              value="oldestFirst"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Oldest First
            </ToggleButton>
          </ToggleButtonGroup>

          <h3 style={{ marginLeft: "50px" }}>
            {totalResults} results for "{searchTerm}":
          </h3>
          <DisplayGames games={searchResults} />
          <Stack spacing={2}>
            <div className="pagination-container">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
              />
            </div>
          </Stack>
        </div>
      ) : (
        <p>Enter a search term to see results.</p>
      )}
    </div>
  );
};

export default Search;
