import GameContext from "./GameContext";
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import UserContext from "./UserContext";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CardHeader } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Rating from "@mui/material/Rating";
import DOMPurify from "dompurify";
import { differenceInYears } from "date-fns";
import { SERVER_ADDRESS } from "./config";
import noImage from "../images/no_image_avail.JPG";

//const serverAddress = "http://localhost:4000";
//const serverAddress = "http://loadbalancervgproject-11834139.us-east-2.elb.amazonaws.com:4000";
const todayDate = new Date();

const Game = () => {
  const { user, setUser } = useContext(UserContext);
  const { game } = useContext(GameContext);
  const [dateStarted, setDateStarted] = useState(null);
  const [dateEnded, setDateEnded] = useState(null);
  const [gameNotes, setGameNotes] = useState("");
  const [tipsAndTricks, setTipsAndTricks] = useState("");
  const [developerNotes, setDeveloperNotes] = useState("");
  const [gameInfoExists, setGameInfoExists] = useState(false);
  const [gameRating, setGameRating] = useState(null);
  const [wishListExists, setWishListExists] = useState(null);
  const [gamesPlayedExists, setGamesPlayedExists] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [dateErrorText, setDateErrorText] = useState("");

  console.log(Array.isArray(user.wishlist));
  console.log(user.wishlist);

  const handleValidateGameInfo = () => {
    console.log("Hello" + differenceInYears(todayDate, dateStarted));

    let errorExists = 0;
    setErrorText("");
    setDateErrorText("");
    if (
      gameNotes.length > 500 ||
      tipsAndTricks.length > 500 ||
      developerNotes.length > 500
    ) {
      setErrorText("Max length for notes is 500 characters");
      errorExists = 1;
    }

    if (dateStarted) {
      if (
        differenceInYears(todayDate, dateStarted) < 0 ||
        differenceInYears(todayDate, dateStarted) > 50
      ) {
        setDateErrorText("Please double check date");
        errorExists = 1;
      }
    }

    if (dateEnded) {
      if (
        differenceInYears(todayDate, dateEnded) < 0 ||
        differenceInYears(todayDate, dateEnded) > 50
      ) {
        setDateErrorText("Please double check date");
        errorExists = 1;
      }
    }

    if (dateEnded && dateStarted) {
      if (differenceInYears(dateEnded, dateStarted) < 0) {
        setDateErrorText("Please double check date");
        errorExists = 1;
      }
    }

    if (
      gameNotes == "" &&
      tipsAndTricks == "" &&
      developerNotes == "" &&
      dateStarted == null &&
      dateEnded == null &&
      gameRating == null
    ) {
      setErrorText("Nothing to save");
      errorExists = 1;
    }

    if (errorExists === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (user && user.gameInfo) {
      console.log("User Game Info:", user.gameInfo);
      console.log("Game ID:", game.id);
      const gameInfo = user.gameInfo.find(
        (info) => Number(info.id) === Number(game.id),
      );

      if (gameInfo) {
        setGameInfoExists(true);
        setGameNotes(gameInfo.gameNotes || "");
        setTipsAndTricks(gameInfo.tipsAndTricks || "");
        setDeveloperNotes(gameInfo.developerNotes || "");
        setDateStarted(
          gameInfo.dateStarted ? new Date(gameInfo.dateStarted) : null,
        );
        setDateEnded(gameInfo.dateEnded ? new Date(gameInfo.dateEnded) : null);
        setGameRating(gameInfo.gameRating ?? null);
      } else {
        setGameInfoExists(false);
        setGameNotes("");
        setTipsAndTricks("");
        setDeveloperNotes("");
        setDateStarted(null);
        setDateEnded(null);
        setGameRating(null);
      }
    }

    if (user && user.wishlist) {
      const wishlist = user.wishlist.find(
        (info) => Number(info.id) === Number(game.id),
      );
      if (wishlist) {
        setWishListExists(true);
        console.log("Game is in the wishlist");
      } else {
        setWishListExists(false);
        console.log("Game is NOT in the wishlist");
      }
    }

    if (user && user.gamesPlayed) {
      const gamesPlayed = user.gamesPlayed.find(
        (info) => Number(info.id) === Number(game.id),
      );
      if (gamesPlayed) {
        setGamesPlayedExists(true);
        console.log("Game is in the games played list");
      } else {
        setGamesPlayedExists(false);
        console.log("Game is NOT in the games played list");
      }
    }
  }, [user, user?.wishlist, user?.gamesPlayed, game.id]);

  const handleStartedChange = (newValue) => {
    setDateStarted(newValue);
  };
  const handleEndedChange = (newValue) => {
    setDateEnded(newValue);
  };

  const handleAddToGamesDB = async () => {
    try {
      const gameExists = await doesGameExist(game.id);

      // If the game does not exist, add it to the games collection
      if (!gameExists) {
        await axios.post(
          `${SERVER_ADDRESS}/api/games`,
          {
            id: game.id,
            name: game.name,
            image: game.image,
            metacritic: game.metacritic,
            publishers: game.publishers,
            developers: game.developers,
            release_date: game.release_date,
            platforms: game.platforms,
            genres: game.genres,
            description: game.description,
          },
          { withCredentials: true },
        );
      }
    } catch (error) {
      console.error("Error creating game:", error);
      alert("There was an error creating the game.");
    }
  };

  const id = Number(game.id);

  const handleSaveGameInfo = async () => {
    if (handleValidateGameInfo() == true) {
      const sanitizedGameNotes = DOMPurify.sanitize(gameNotes);
      const sanitizedTipsAndTricks = DOMPurify.sanitize(tipsAndTricks);
      const sanitizedDeveloperNotes = DOMPurify.sanitize(developerNotes);

      try {
        handleAddToGamesDB();

        const gameData = {
          id: Number(game.id),
          gameNotes: sanitizedGameNotes || "",
          tipsAndTricks: sanitizedTipsAndTricks || "",
          developerNotes: sanitizedDeveloperNotes || "",
          dateStarted: dateStarted ? dateStarted.toISOString() : null,
          dateEnded: dateEnded ? dateEnded.toISOString() : null,
          gameRating,
        };

        if (gameInfoExists) {
          // If gameInfo exists, perform update (PUT)
          await axios.put(
            `${SERVER_ADDRESS}/api/user/${user.username}/gameinfo/${id}`,
            gameData,
            { withCredentials: true },
          );
        } else {
          // If gameInfo doesn't exist, create a new entry (POST)
          await axios.post(
            `${SERVER_ADDRESS}/api/user/${user.username}/gameinfo`,
            gameData,
            { withCredentials: true },
          );

          setGameInfoExists(true);
        }

        const fullInfoResponse = await axios.get(
          `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
          { withCredentials: true },
        );
        setUser(fullInfoResponse.data);
      } catch (error) {
        console.error("Error saving game info:", error);
        alert("There was an error saving the game info.");
      }
    }
  };

  const handleDeleteGameInfo = async () => {
    try {
      await axios.delete(
        `${SERVER_ADDRESS}/api/user/${user.username}/gameinfo/${id}`,
        { withCredentials: true },
      );

      // Refresh user data after deletion
      const fullInfoResponse = await axios.get(
        `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
        { withCredentials: true },
      );
      setUser(fullInfoResponse.data);

      // Clear the fields after deletion
      setGameNotes("");
      setTipsAndTricks("");
      setDeveloperNotes("");
      setDateStarted(null);
      setDateEnded(null);
      setGameInfoExists(false);
      setGameRating(null);
      setErrorText("");
      setDateErrorText("");
    } catch (error) {
      console.error("Error deleting game info:", error);
      alert("There was an error deleting the game info.");
    }
  };

  const doesGameExist = async (gameId) => {
    try {
      const response = await axios.get(
        `${SERVER_ADDRESS}/api/games/${gameId}`,
        {
          withCredentials: true,
        },
      );
      return response.data.exists;
    } catch (error) {
      console.error("Error checking if game exists:", error);
      return false;
    }
  };

  const handleAddToWishlist = async () => {
    try {
      const gameExists = await doesGameExist(game.id);

      // If the game does not exist, add it to the games collection

      handleAddToGamesDB();

      // Add the game to the user's wishlist
      await axios.post(
        `${SERVER_ADDRESS}/api/user/${user.username}/wishlist`,
        { gameId: game.id },
        { withCredentials: true },
      );
      const fullInfoResponse = await axios.get(
        `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
        {
          withCredentials: true,
        },
      );
      setUser(fullInfoResponse.data);
    } catch (error) {
      console.error("Error adding game to wishlist:", error);
      alert("There was an error adding the game to your wishlist.");
    }
  };

  const handleRemoveFromWishList = async () => {
    try {
      await axios.delete(
        `${SERVER_ADDRESS}/api/user/${user.username}/wishlist/${game.id}`,
        { withCredentials: true },
      );

      // Refresh user data after deletion
      const fullInfoResponse = await axios.get(
        `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
        { withCredentials: true },
      );
      setUser(fullInfoResponse.data);

      // Clear the fields after deletion
      setWishListExists(null);
    } catch (error) {
      console.error("Error deleting the game from wishlist:", error);
      alert("There was an error deleting the game from the wishlist.");
    }
  };

  const handleAddToGamesPlayed = async () => {
    try {
      const gameExists = await doesGameExist(game.id);

      handleAddToGamesDB();

      await axios.post(
        `${SERVER_ADDRESS}/api/user/${user.username}/gamesPlayed`,
        {
          gameId: game.id,
        },
        {
          withCredentials: true,
        },
      );

      const fullInfoResponse = await axios.get(
        `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
        {
          withCredentials: true,
        },
      );

      setUser(fullInfoResponse.data);
    } catch (error) {
      console.error(
        "Error adding game to games played:",
        error.response?.data || error.message,
      );
      alert("There was an error adding the game to your played games.");
    }
  };

  const handleRemoveGamesPlayed = async () => {
    try {
      await axios.delete(
        `${SERVER_ADDRESS}/api/user/${user.username}/gamesPlayed/${game.id}`,
        { withCredentials: true },
      );

      // Refresh user data after deletion
      const fullInfoResponse = await axios.get(
        `${SERVER_ADDRESS}/api/user/${user.username}/full-info`,
        { withCredentials: true },
      );
      setUser(fullInfoResponse.data);

      // Clear the fields after deletion
      setGamesPlayedExists(null);
    } catch (error) {
      console.error("Error deleting the game from games played list:", error);
      alert("There was an error deleting the game from the games played list.");
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={2}
      sx={{ marginLeft: "100px" }}
    >
      <Box flex={1}>
        <div className="dashboard-main-container">
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            sx={{ justifyContent: "flex-start" }}
          >
            {/* ****************START CARD DESIGN**************** */}

            <Card sx={{ width: 1400 }}>
              <CardHeader
                title={game.name}
                titleTypographyProps={{
                  fontSize: "42px",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                }}
              />
              <CardMedia
                sx={{ height: 550 }}
                image={game.image ? game.image : noImage}
                title="Game Image"
              />
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#3000ff",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {game.description && "Game Description"}
                </Typography>
                <Typography sx={{ color: "text.secondary", fontSize: "16px" }}>
                  {game.description}
                  <br />
                  <br />

                  <Box
                    sx={{ display: "flex", justifyContent: "left", gap: 10 }}
                  >
                    {game.genres && game.genres.length > 0 && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            color: "#3000ff",
                            fontWeight: "bold",
                          }}
                        >
                          Genres
                        </Typography>

                        {game.genres.map((genre, index) => (
                          <span key={index}>
                            {/* This puts a comma at every one except for the last */}
                            {genre}
                            {index < game.genres.length - 1 ? <br /> : ""}
                          </span>
                        ))}
                      </Box>
                    )}
                    {game.platforms && game.platforms.length > 0 && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            color: "#3000ff",
                            fontWeight: "bold",
                          }}
                        >
                          Platforms
                        </Typography>
                        {game.platforms.map((platform, index) => (
                          <span key={index}>
                            {/* This puts a comma at every one except for the last */}
                            {platform}
                            {index < game.platforms.length - 1 ? <br /> : ""}
                          </span>
                        ))}
                      </Box>
                    )}

                    {game.release_date && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            color: "#3000ff",
                            fontWeight: "bold",
                          }}
                        >
                          Release Date
                        </Typography>
                        {new Date(game.release_date).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          },
                        )}{" "}
                        <br />
                      </Box>
                    )}
                    {game.publishers && game.publishers.length > 0 && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            color: "#3000ff",
                            fontWeight: "bold",
                          }}
                        >
                          Publishers
                        </Typography>
                        {game.publishers.map((publisher, index) => (
                          <span key={index}>
                            {/* This puts a comma at every one except for the last */}
                            {publisher}
                            {index < game.publishers.length - 1 ? <br /> : ""}
                          </span>
                        ))}
                      </Box>
                    )}
                    {game.developers && game.developers.length > 0 && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            color: "#3000ff",
                            fontWeight: "bold",
                          }}
                        >
                          Developers
                        </Typography>
                        {game.developers.map((developer, index) => (
                          <span key={index}>
                            {/* This puts a comma at every one except for the last */}
                            {developer}
                            {index < game.developers.length - 1 ? <br /> : ""}
                          </span>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Typography>
              </CardContent>
              <CardActions>
                {!wishListExists && (
                  <Button
                    variant="contained"
                    onClick={handleAddToWishlist}
                    sx={{
                      marginRight: 2,
                      backgroundColor: "#3000FF",
                      color: "white",
                    }}
                  >
                    Add to Wishlist
                  </Button>
                )}
                {wishListExists && (
                  <Button
                    variant="contained"
                    onClick={handleRemoveFromWishList}
                    sx={{
                      marginRight: 2,
                      backgroundColor: "#D3D3D3",
                      color: "black",
                    }}
                  >
                    Remove from Wishlist
                  </Button>
                )}

                {/* Button to add game to played games */}
                {!gamesPlayedExists && (
                  <Button
                    variant="contained"
                    onClick={handleAddToGamesPlayed}
                    sx={{
                      marginRight: 2,
                      backgroundColor: "#3000FF",
                      color: "white",
                    }}
                  >
                    Add to Games Played
                  </Button>
                )}
                {gamesPlayedExists && (
                  <Button
                    variant="contained"
                    onClick={handleRemoveGamesPlayed}
                    sx={{
                      marginRight: 2,
                      backgroundColor: "#D3D3D3",
                      color: "black",
                    }}
                  >
                    Remove from Games Played
                  </Button>
                )}
              </CardActions>
            </Card>
          </Box>
        </div>
      </Box>

      <Box
        sx={{
          width: 800,
          padding: 2,
          borderLeft: "1px solid #ccc",
          height: "100vh",
        }}
      >
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1 } }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label="Game Notes"
              id="game-notes"
              multiline
              rows={9}
              fullWidth
              variant="outlined"
              value={gameNotes}
              onChange={(e) => setGameNotes(e.target.value)}
            />
          </div>
          <div>
            <TextField
              label="Tips and Tricks"
              id="tips-and-tricks"
              multiline
              rows={9}
              fullWidth
              variant="outlined"
              value={tipsAndTricks}
              onChange={(e) => setTipsAndTricks(e.target.value)}
            />
          </div>
          <div>
            <TextField
              label="Notes to the Developer"
              id="developer-notes"
              multiline
              rows={9}
              fullWidth
              variant="outlined"
              value={developerNotes}
              onChange={(e) => setDeveloperNotes(e.target.value)}
            />
          </div>

          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography sx={{ marginRight: "10px", fontSize: "16px" }}>
              Game Rating:
            </Typography>
            <Rating
              name="game-rating"
              value={gameRating}
              precision={0.5}
              onChange={(event, newValue) => setGameRating(newValue)}
              sx={{ fontSize: "28px" }}
            />
          </Box>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date Started"
              value={dateStarted}
              onChange={handleStartedChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date Ended"
              value={dateEnded}
              onChange={handleEndedChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginTop: 2, width: "100%" }}
          >
            <Box sx={{ textAlign: "left", flexGrow: 1 }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "red",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  textAlign: "left",
                  minHeight: "40px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                component="div"
              >
                {errorText}
                {errorText && dateErrorText && <br />}
                {dateErrorText}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleSaveGameInfo}
                sx={{
                  marginRight: 2,
                  backgroundColor: "#3000FF",
                  color: "white",
                }}
              >
                {gameInfoExists ? "Update Game Info" : "Save Game Info"}
              </Button>
              {gameInfoExists && (
                <Button
                  variant="contained"
                  onClick={handleDeleteGameInfo}
                  sx={{
                    marginRight: 2,
                    backgroundColor: "#3000FF",
                    color: "white",
                  }}
                >
                  Delete Game Info
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Game;
