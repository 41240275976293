import { Link } from "react-router-dom";

// imports icons pictures for use in footer
import facebookIcon from "../images/facebook.png";
import instagramIcon from "../images/instagram.png";
import youtubeIcon from "../images/youtube.png";
import discordIcon from "../images/discord.png";

const Footer = () => {
  return (
    <footer className="footerContainer">
      <div className="footerLeft">
        Copyright 2024 by Adam Slager. All Rights Reserved.
      </div>

      <div className="footerLinks">
        <Link to="/about">about us</Link>
        <Link to="/contact">contact us</Link>
        <Link to="/faq">faq</Link>
      </div>

      <div className="footerRight">
        <a href="http://www.facebook.com">
          <img src={facebookIcon} alt="Facebook Icon" className="socialIcons" />
        </a>
        <a href="http://www.instagram.com">
          <img
            src={instagramIcon}
            alt="Instagram Icon"
            className="socialIcons"
          />
        </a>
        <a href="http://www.youtube.com">
          <img src={youtubeIcon} alt="Youtube Icon" className="socialIcons" />
        </a>
        <a href="http://www.discord.com">
          <img src={discordIcon} alt="Discord Icon" className="socialIcons" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
