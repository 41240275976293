import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import searchImage from "../images/search.JPG";
import recommendImage from "../images/recommend.JPG";
import manageImage from "../images/manage.JPG";
import controllerImage from "../images/icon_game_controller_largercanvas_white.png";
import filterImage from "../images/filter.JPG";
import researchImage from "../images/research.JPG";
import commentImage from "../images/note_and_comment.JPG";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Home = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          fontSize: "96px",
          marginTop: 2,
          marginLeft: 7,
          marginBottom: 1,
          fontWeight: "Bold",
          fontFamily: "Open Sans",
          color: "#3000ff",
        }}
      >
        Find your next game.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: "30px",
          marginLeft: 9,
          marginBottom: 4,
          fontFamily: "Open Sans",
          color: "black",
        }}
      >
        A video game recommendation engine built by gamers for gamers.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: "28px",
          marginLeft: 9,
          marginBottom: 4,
          fontFamily: "Open Sans",
          color: "black",
        }}
      >
        With MistyPath, the power is in your hands to:
      </Typography>

      <div
        className="slider-container"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Slider {...settings}>
          <div>
            <Card
              sx={{
                width: 550,
                height: 530,
                marginLeft: 7,
                marginTop: 2,
                marginBottom: 3,

                borderRadius: "16px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardHeader
                sx={{ backgroundColor: "#3000ff" }}
                avatar={
                  <Avatar
                    src={controllerImage}
                    alt="Controller Image"
                    sx={{
                      width: 50,
                      height: 50,
                      img: {
                        objectFit: "contain",
                        objectPosition: "center",
                      },
                    }}
                  />
                }
                title="Search"
                titleTypographyProps={{
                  sx: {
                    fontSize: "36px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    color: "white",
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{ objectPosition: "top" }}
                height="300"
                image={searchImage}
                alt="Search Example"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontFamily: "Open Sans" }}
                >
                  Robust search and filter functionality lets you find the game
                  you want to play.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              sx={{
                width: 550,
                height: 530,
                marginLeft: 7,
                marginTop: 2,
                marginBottom: 3,

                borderRadius: "16px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardHeader
                sx={{ backgroundColor: "#3000ff" }}
                avatar={
                  <Avatar
                    src={controllerImage}
                    alt="Controller Image"
                    sx={{
                      width: 50,
                      height: 50,
                      img: {
                        objectFit: "contain",
                        objectPosition: "center",
                      },
                    }}
                  />
                }
                title="Get Recommendations"
                titleTypographyProps={{
                  sx: {
                    fontSize: "36px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    color: "white",
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{ objectPosition: "top" }}
                height="300"
                image={recommendImage}
                alt="Recommender Example"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontFamily: "Open Sans" }}
                >
                  Video game recommendation algorithm that uses your video game
                  ratings to find your next favorite game
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              sx={{
                width: 550,
                height: 530,
                marginLeft: 7,
                marginTop: 2,
                marginBottom: 3,

                borderRadius: "16px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardHeader
                sx={{ backgroundColor: "#3000ff" }}
                avatar={
                  <Avatar
                    src={controllerImage}
                    alt="Controller Image"
                    sx={{
                      width: 50,
                      height: 50,
                      img: {
                        objectFit: "contain",
                        objectPosition: "center",
                      },
                    }}
                  />
                }
                title="Research Games"
                titleTypographyProps={{
                  sx: {
                    fontSize: "36px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    color: "white",
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{ objectPosition: "left" }}
                height="300"
                image={researchImage}
                alt="Manage Example"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontFamily: "Open Sans" }}
                >
                  Detailed and organized video game info that allows you to make
                  informed decisions quickly
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <Card
              sx={{
                width: 550,
                height: 530,
                marginLeft: 7,
                marginTop: 2,
                marginBottom: 3,

                borderRadius: "16px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardHeader
                sx={{ backgroundColor: "#3000ff" }}
                avatar={
                  <Avatar
                    src={controllerImage}
                    alt="Controller Image"
                    sx={{
                      width: 50,
                      height: 50,
                      img: {
                        objectFit: "contain",
                        objectPosition: "center",
                      },
                    }}
                  />
                }
                title="Note and Comment"
                titleTypographyProps={{
                  sx: {
                    fontSize: "36px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    color: "white",
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{ objectPosition: "top" }}
                height="300"
                image={commentImage}
                alt="Manage Example"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontFamily: "Open Sans" }}
                >
                  Create personalized comments, dates played, and ratings for
                  any video game
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div>
            <Card
              sx={{
                width: 550,
                height: 530,
                marginLeft: 7,
                marginTop: 2,
                marginBottom: 3,

                borderRadius: "16px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardHeader
                sx={{ backgroundColor: "#3000ff" }}
                avatar={
                  <Avatar
                    src={controllerImage}
                    alt="Controller Image"
                    sx={{
                      width: 50,
                      height: 50,
                      img: {
                        objectFit: "contain",
                        objectPosition: "center",
                      },
                    }}
                  />
                }
                title="Manage Your Collection"
                titleTypographyProps={{
                  sx: {
                    fontSize: "36px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    color: "white",
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{ objectPosition: "top" }}
                height="300"
                image={manageImage}
                alt="Manage Example"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontFamily: "Open Sans" }}
                >
                  Add games to your collections of games played or wishlisted
                  games
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div>
            <Card
              sx={{
                width: 550,
                height: 530,
                marginLeft: 7,
                marginTop: 2,
                marginBottom: 3,

                borderRadius: "16px",
                boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardHeader
                sx={{ backgroundColor: "#3000ff" }}
                avatar={
                  <Avatar
                    src={controllerImage}
                    alt="Controller Image"
                    sx={{
                      width: 50,
                      height: 50,
                      img: {
                        objectFit: "contain",
                        objectPosition: "center",
                      },
                    }}
                  />
                }
                title="Sort and Filter"
                titleTypographyProps={{
                  sx: {
                    fontSize: "36px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    color: "white",
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{ objectPosition: "top" }}
                height="300"
                image={filterImage}
                alt="Manage Example"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontFamily: "Open Sans" }}
                >
                  Sort and filter your collections to find exactly the games you
                  are looking for
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Slider>
      </div>
      <Typography
        variant="h4"
        sx={{
          fontSize: "30px",
          marginLeft: 7,
          marginTop: 5,
          fontFamily: "Open Sans",
          color: "black",
        }}
      >
        <Link to="/Login">Login</Link> or{" "}
        <Link to="/Create">Create an account</Link> to begin
      </Typography>
    </div>
  );
};

export default Home;
