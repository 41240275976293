import React, { useState, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import UserContext from "./UserContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import gameController from "../images/icon_game_controller_.png";
import axios from "axios";
import DOMPurify from "dompurify";
import { SERVER_ADDRESS } from "./config";

//const serverAddress =   "http://loadbalancervgproject-11834139.us-east-2.elb.amazonaws.com:4000";
//const serverAddress = "http://localhost:4000";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const validateLogin = async (event) => {
    event.preventDefault();

    // Users DOMPurify library to santize user inputs to protect against XSS attacks
    const sanitizedusername = DOMPurify.sanitize(username);
    console.log(sanitizedusername);

    const sanitizedPassword = DOMPurify.sanitize(password);
    console.log(sanitizedPassword);

    try {
      const response = await axios.post(
        `${SERVER_ADDRESS}/api/user/login`,
        {
          username: username,
          password,
        },
        {
          withCredentials: true, // Add this to allow session cookies
        },
      );
      // If it does, utilize setUser via UserContext to set the user name context so other parts of the app can access it

      // HERE
      const loggedInUsername = response.data.username;

      const fullInfoResponse = await axios.get(
        `${SERVER_ADDRESS}/api/user/${loggedInUsername}/full-info`,
        {
          withCredentials: true, // Include credentials to maintain session
        },
      );

      setUser(fullInfoResponse.data);
      // Clear any previous errors
      setErrorMessage(null);
      // automatically navigate to dashboard to the user is logged in
      // navigate("/dashboard");
    } catch (error) {
      setErrorMessage("Login Failed: " + error.response?.data?.error);
    }
  };

  return (
    <div className="centered-login-container">
      {/* Creates MUI card in center of screen for Login Page and makes it a rounded black box*/}
      <Card
        className="overallLoginCard"
        sx={{
          minWidth: 500, //makes sure its wide enough to match wireframe
          minHeight: 450, //makes sure its tall enough to match wireframe
          borderRadius: "20px", // rounds the edges of the box
          border: "2px solid black", // black border to match wireframe
          paddingRight: 10, //moves items away from right border
          paddingLeft: 10, //moves items away from left border
          paddingTop: 5, //moves items away from top border
        }}
      >
        {/* Sets up a flexbox inside of the card that arranges components vertically */}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* First items inside of flexbox holding title of Login Page */}
          <Box
            // sets up the flexbox item to arrange title and icon to match wireframe
            display="flex" // sets up flexbox
            paddingLeft="50px" // moves icone and text to near center of screen
            alignItems="center" // centers vertically
            marginBottom="24px" // allows space between title and input boxes
          >
            {/* Icon of the controller */}
            <img
              src={gameController}
              alt="Game Controller"
              style={{ width: 59, height: 43, marginRight: 15 }}
            />

            {/* Sets up font for Welcome back */}
            <Typography
              sx={{
                fontSize: "36px",
                color: "#3000ff",
                fontWeight: "bold",
                fontFamily: "Open Sans",
              }}
            >
              Welcome Back!
            </Typography>
          </Box>

          {/* Second set of items of flexbox holding for User Name, PW, Forgot Username/Password, and Sign in Button */}
          <form onSubmit={validateLogin}>
            <Box
              display="flex" // sets up flexbox
              alignItems="center" // centers elements
              flexDirection="column" // arranges all elements up and down
            >
              {/* Second set of items of flexbox holding for User Name, PW, Forgot Username/Password, and Sign in Button */}

              {/* Input for User Name */}
              <TextField
                required
                id="username"
                label="User Name"
                variant="filled"
                value={username}
                onChange={(e) => setusername(e.target.value)} // If data is input, changes state variable username to match the input
                // Changes default look of input box to match color scheme of site as set in wireframe
                autoComplete="username"
                sx={{
                  minWidth: "400px", // sets the min size of the bxo
                  marginBottom: "20px", // puts some space between the username and password fields
                  borderRadius: "10px", // rounds the input boxes
                  border: "2px solid black", //puts a black border all around the input box
                  "& .MuiInputBase-root": {
                    backgroundColor: "white", // This sets the background color to white (as opposed to original gray)
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />

              {/* Input for Password */}
              <TextField
                required
                id="passwordInput"
                label="Password"
                type="password" // sets type to password to hide password as it is typed
                variant="filled"
                value={password}
                // If data is input, changes state variable username to match the input
                onChange={(e) => setPassword(e.target.value)}
                // Changes default look of input box to match color scheme of site as set in wireframe
                autoComplete="current-password"
                sx={{
                  minWidth: "400px", //sets the minimum size of teh box
                  borderRadius: "10px", // rounds the input boxes
                  border: "2px solid black", //puts a black border all around the input box
                  "& .MuiInputBase-root": {
                    backgroundColor: "white", // This sets the background color to white (as opposed to original gray)
                  },
                  "& .MuiFilledInput-root": {
                    borderRadius: "10px", // This makes the textbox rounded, as opposed to the default square box
                  },
                }}
              />

              {/* Text for Forgot Username/Password --- will implement logic later*/}
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#3000ff",
                  fontFamily: "Open Sans",
                  textAlign: "right",
                  alignSelf: "flex-end",
                  marginRight: "40px",
                }}
                component="div"
              >
                Forgot Username/Password
              </Typography>

              <Button
                id="signInButton"
                sx={{
                  backgroundColor: "#3000ff",
                  fontSize: "28px",
                  fontFamily: "Open Sans",
                  minWidth: "400px",
                  height: "45px",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontWeight: "normal",
                  marginTop: "25px",
                }}
                variant="contained"
                type="submit"
              >
                Sign In
              </Button>
            </Box>
          </form>

          <Typography
            sx={{
              fontSize: "16px",
              color: "red",
              fontFamily: "Open Sans",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
            component="div"
          >
            {errorMessage}
          </Typography>

          {/* Third MUI box just create page at bottom of card */}
          <Typography
            sx={{
              fontSize: "16px",
              color: "#3000ff",
              fontFamily: "Open Sans",
              marginBottom: "40px",
              textAlign: "center",
            }}
            component="div"
          >
            Don't Have An Account? <NavLink to="/create">Create One</NavLink>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
