import React, { useContext, useState, useEffect } from "react";
import UserContext from "./UserContext";
import GameContext from "./GameContext";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import noImage from "../images/no_image_avail.JPG";

const Dashboard = () => {
  // user context variable for dashboard customization
  const { user } = useContext(UserContext);
  const { setGame } = useContext(GameContext);

  const navigate = useNavigate();
  // creates state variables using useState hook for toggle button states
  // also, sets initial states
  const [alignment, setAlignment] = useState("wishlist");
  const [sort, setSort] = useState("a-to-z");

  // creates state variables using useState hook for filter dropdown states
  const [yearFilter, setYearFilter] = useState([]);
  const [genreFilter, setGenreFilter] = useState([]);
  const [devFilter, setDevFilter] = useState([]);
  const [pubFilter, setPubFilter] = useState([]);
  const [scoreFilter, setScoreFilter] = useState([]);
  const [platformAvailFilter, setPlatformAvailFilter] = useState([]);

  // define arrays for use in filters section
  const [releaseYears, setReleaseYears] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [genres, setGenres] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    let games = alignment === "wishlist" ? user.wishlist : user.gamesPlayed;
    if (games && games.length > 0) {
      const gameReleaseYears = getGameReleaseYears(games);
      const gameDevelopers = getGameDevelopers(games);
      const gameGenres = getGameGenres(games);
      const gamePublishers = getGamePublishers(games);
      const gamePlatforms = getgamePlatforms(games);
      setReleaseYears(gameReleaseYears);
      setDevelopers(gameDevelopers);
      setGenres(gameGenres);
      setPublishers(gamePublishers);
      setPlatforms(gamePlatforms);
    } else {
      setReleaseYears([]);
      setDevelopers([]);
      setGenres([]);
      setPublishers([]);
      setPlatforms([]);
    }
  }, [alignment, user.wishlist, user.gamesPlayed]);

  const scores = ["50 - 60", "60 - 70", "70 - 80", "80 - 90", "90 - 100"];

  const handleCardClick = (game) => {
    setGame(game);
    console.log(
      "This is the game: " +
        game.name +
        game.developers +
        game.description +
        game.image,
    );
    navigate("/game");
  };

  // Handle change function for list type switch
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // Handle change function for filter switches
  const handleSortChange = (event, newSort) => {
    setSort(newSort);
  };

  // Function for reset filter button that resets states to null
  const handleResetFilters = () => {
    setYearFilter([]);
    setGenreFilter([]);
    setDevFilter([]);
    setPubFilter([]);
    setScoreFilter([]);
    setPlatformAvailFilter([]);
  };

  // Sort function for games
  function sortGames(games) {
    switch (sort) {
      case "a-to-z":
        return games.sort((a, b) => a.name.localeCompare(b.name));
      case "z-to-a":
        return games.sort((a, b) => b.name.localeCompare(a.name));
      case "metacritic":
        return games.sort((a, b) => b.metascore - a.metascore);
      default:
        return games;
    }
  }

  function filterGames(games) {
    return games.filter((game) => {
      const releaseYear = new Date(game.release_date).getFullYear();
      const gameDevelopers = game.developers || [];
      const gameGenres = game.genres || [];
      const gamePublishers = game.publishers || [];
      const gamePlatforms = game.platforms || [];

      if (yearFilter.length > 0 && !yearFilter.includes(releaseYear)) {
        return false;
      }

      if (
        devFilter.length > 0 &&
        !devFilter.some((dev) => gameDevelopers.includes(dev))
      ) {
        return false;
      }

      if (
        genreFilter.length > 0 &&
        !genreFilter.some((genre) => gameGenres.includes(genre))
      ) {
        return false;
      }

      if (
        pubFilter.length > 0 &&
        !pubFilter.some((publisher) => gamePublishers.includes(publisher))
      ) {
        return false;
      }

      if (
        platformAvailFilter.length > 0 &&
        !platformAvailFilter.some((platform) =>
          gamePlatforms.includes(platform),
        )
      ) {
        return false;
      }

      return true;
    });
  }

  function getGameReleaseYears(games) {
    const gameReleaseYears = [];
    for (let game of games) {
      if (game.release_date) {
        let gameDate = new Date(game.release_date);
        if (!isNaN(gameDate.getTime())) {
          let year = gameDate.getFullYear();
          if (!gameReleaseYears.includes(year)) {
            gameReleaseYears.push(year);
          }
        } else {
          console.log(`Invalid date: ${game.release_date}`);
        }
      }
    }
    return gameReleaseYears;
  }

  function getGameDevelopers(games) {
    const gameDevelopers = new Set();

    for (let game of games) {
      if (game.developers && game.developers.length > 0) {
        game.developers.forEach((developer) => {
          gameDevelopers.add(developer);
        });
      }
    }

    return Array.from(gameDevelopers);
  }

  function getGameGenres(games) {
    const gameGenres = new Set();

    for (let game of games) {
      if (game.genres && game.genres.length > 0) {
        game.genres.forEach((genre) => {
          gameGenres.add(genre);
        });
      }
    }

    return Array.from(gameGenres);
  }

  function getgamePlatforms(games) {
    const gamePlatforms = new Set();

    for (let game of games) {
      if (game.platforms && game.platforms.length > 0) {
        game.platforms.forEach((platform) => {
          gamePlatforms.add(platform);
        });
      }
    }

    return Array.from(gamePlatforms);
  }

  function getGamePublishers(games) {
    const gamePublishers = new Set();

    for (let game of games) {
      if (game.publishers && game.publishers.length > 0) {
        game.publishers.forEach((publisher) => {
          gamePublishers.add(publisher);
        });
      }
    }

    return Array.from(gamePublishers);
  }

  // Main display function for game cards that re-renders based on toggle button changes
  function displayGames(games) {
    const filteredGames = filterGames(games);

    const sortedGames = sortGames(filteredGames);

    // return <DisplayGames games={sortedGames}/>
    return sortedGames.map((game) => (
      <div data-testid={`game-card-${game.id}`} key={game.id}>
        <Card
          key={game.id}
          sx={{
            width: "440px",
            height: "310px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontFamily: "Open Sans",
          }}
        >
          <CardActionArea onClick={() => handleCardClick(game)}>
            <CardMedia
              component="img"
              height="140"
              image={game.image ? game.image : noImage}
              alt={game.name}
              sx={{ objectFit: "cover" }}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                gutterBottom
                component="div"
                sx={{ fontFamily: "Open Sans", fontSize: "20px" }}
              >
                {game.name}
              </Typography>
              <Grid container spacing="2px" marginTop={"10px"}>
                <Grid size={5}>
                  <Typography
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  >
                    Critic Score:
                  </Typography>
                </Grid>

                <Grid size={7}>
                  <Typography
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  >
                    {game.metascore ? game.metascore : "No Reviews"}
                  </Typography>
                </Grid>
                {/* Developer: {game.developers} <br /> */}
                <Grid size={5}>
                  <Typography
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  >
                    Release Date:{" "}
                  </Typography>
                </Grid>
                <Grid size={7}>
                  <Typography
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  >
                    {new Date(game.release_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                  </Typography>
                </Grid>
                <Grid size={5}>
                  <Typography
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  >
                    Genre:
                  </Typography>
                </Grid>
                <Grid size={7}>
                  <Typography
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  >
                    {game.genres.join(", ")} <br />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    ));
  }

  // MAIN RETURN FUNCTION FOR PAGE - EXECUTES AS FOLLOWS:
  // 1 - Creates flex box to store overall page
  // 2 - Creates flexbox for top left content (i.e the toggle switches)
  // 3 - Displays Toggle for Wishlist/Upcoming Games
  // 4 - Displays Toggle for Sort Options
  // 5 - Creates new flexbox item for card content
  // 6 - Calls DisplayGames Helper Function and passes either wishlist or upcoming games array
  // 7 - Displays rightbox filters section

  return (
    // 1 - Creates flex box to store overall page
    <Box
      display="flex"
      justifyContent="space-between"
      gap={2}
      sx={{ marginLeft: "100px" }}
    >
      {/* 2 - Creates flexbox for top left content (i.e the toggle switches)   */}
      <Box flex={1}>
        <Box display="flex" gap={2} marginBottom={2}>
          {/* 3 - Displays Toggle for Wishlist/Upcoming Games */}
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ fontFamily: "Open Sans" }}
          >
            <ToggleButton
              value="wishlist"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              wishlist
            </ToggleButton>
            <ToggleButton
              value="upcoming-games"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff", //
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              my games
            </ToggleButton>
          </ToggleButtonGroup>

          {/* 4 - Displays Toggle for Sort Options  */}
          <ToggleButtonGroup
            color="secondary"
            value={sort}
            exclusive
            onChange={handleSortChange}
            aria-label="Sort Options"
            sx={{ fontFamily: "Open Sans" }}
          >
            {/* A to Z Sort  */}
            <ToggleButton
              value="a-to-z"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              A to Z
            </ToggleButton>

            {/* Z to A Sort */}
            <ToggleButton
              value="z-to-a"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Z to A
            </ToggleButton>

            {/* Metacritic Score Sort */}
            <ToggleButton
              value="metacritic"
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3000ff",
                  "&:hover": {
                    backgroundColor: "#3000ff",
                  },
                },
              }}
            >
              Metacritic
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <div className="dashboard-main-container">
          {/* 5 - Creates new flexbox item for card content */}
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            sx={{ justifyContent: "flex-start" }}
          >
            {/* 6 - Calls DisplayGames Helper Function and passes either wishlist or upcoming games array         */}
            {alignment === "wishlist" && displayGames(user.wishlist)}
            {alignment === "upcoming-games" && displayGames(user.gamesPlayed)}
          </Box>
        </div>
      </Box>

      {/* 7 - Displays rightbox filters section - ADDITIONAL LOGIC TO BE ADDED */}
      <Box
        sx={{
          width: 400,
          padding: 2,
          borderLeft: "1px solid #ccc",
          height: "100vh",
        }}
      >
        <h3
          style={{
            fontFamily: "Open Sans",
            color: "#3000ff",
            textAlign: "center",
            fontSize: "24px",
          }}
        >
          Filters
        </h3>

        <FormControl
          fullWidth
          sx={{ marginBottom: 3, fontFamily: "Open Sans" }}
        >
          {/* Filter Section for Release Year  */}
          <InputLabel>Release Year</InputLabel>
          <Select
            multiple
            value={yearFilter}
            onChange={(e) => setYearFilter(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {releaseYears.map((year) => (
              <MenuItem key={year} value={year}>
                <Checkbox checked={yearFilter.includes(year)} />
                <ListItemText primary={year} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter Section for Genre */}
        <FormControl
          fullWidth
          sx={{ marginBottom: 3, fontFamily: "Open Sans" }}
        >
          <InputLabel>Genre</InputLabel>
          <Select
            multiple
            value={genreFilter}
            onChange={(e) => setGenreFilter(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {genres.map((genre) => (
              <MenuItem key={genre} value={genre}>
                <Checkbox checked={genreFilter.includes(genre)} />
                <ListItemText primary={genre} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter Section for Game Developer */}
        <FormControl
          fullWidth
          sx={{ marginBottom: 3, fontFamily: "Open Sans" }}
        >
          <InputLabel>Game Developer</InputLabel>
          <Select
            multiple
            value={devFilter}
            onChange={(e) => setDevFilter(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {developers.map((developer) => (
              <MenuItem key={developer} value={developer}>
                <Checkbox checked={devFilter.includes(developer)} />
                <ListItemText primary={developer} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter Section for Platform Availability */}
        <FormControl
          fullWidth
          sx={{ marginBottom: 3, fontFamily: "Open Sans" }}
        >
          <InputLabel>Platform Availability</InputLabel>
          <Select
            multiple
            value={platformAvailFilter}
            onChange={(e) => setPlatformAvailFilter(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                <Checkbox checked={platformAvailFilter.includes(platform)} />
                <ListItemText primary={platform} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter Section for Game Publisher */}
        <FormControl
          fullWidth
          sx={{ marginBottom: 3, fontFamily: "Open Sans" }}
        >
          <InputLabel>Game Publisher</InputLabel>
          <Select
            multiple
            value={pubFilter}
            onChange={(e) => setPubFilter(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {publishers.map((publisher) => (
              <MenuItem key={publisher} value={publisher}>
                <Checkbox checked={pubFilter.includes(publisher)} />
                <ListItemText primary={publisher} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filter Section for Score Range */}
        <FormControl
          fullWidth
          sx={{ marginBottom: 3, fontFamily: "Open Sans" }}
        >
          <InputLabel>Score Range</InputLabel>
          <Select
            multiple
            value={scoreFilter}
            onChange={(e) => setScoreFilter(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {scores.map((score) => (
              <MenuItem key={score} value={score}>
                <Checkbox checked={scoreFilter.includes(score)} />
                <ListItemText primary={score} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Reset Filters Button */}
        <Button
          variant="contained"
          fullWidth
          onClick={handleResetFilters}
          sx={{
            marginTop: 5,
            fontFamily: "Open Sans",
            backgroundColor: "#3000ff",
            color: "#fff",
          }}
        >
          Reset Filters
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
