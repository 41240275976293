import React from "react";

// Create a new context variable so that user can be shared throughout
// the components of the app. Login page will call this function and
// set the user context to the user object that is

const GameContext = React.createContext({
  game: null, // Initially, no game
  setGame: () => {},
});

export default GameContext;
