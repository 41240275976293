// Placeholder for Contact Us Page

const Contact = () => {
  return (
    <div className="contact">
      <h1>Contact Us</h1>
    </div>
  );
};

export default Contact;
