import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./UserContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import gameController from "../images/icon_game_controller_.png";
import Button from "@mui/material/Button";
import axios from "axios";
import { SERVER_ADDRESS } from "./config";

//const serverAddress = "http://loadbalancervgproject-11834139.us-east-2.elb.amazonaws.com:4000";
//const serverAddress = "http://localhost:4000";

const MyAccount = () => {
  const { user, setUser } = useContext(UserContext);
  const [deleteMessage, setDeleteMessage] = useState("");

  const navigate = useNavigate();
  console.log("Hello" + user);
  //****** THIS SECTION LOGS OUT BY 1) SETTING USER CONTEXT VARIABLE TO NULL 2) REMOVING USER
  //******* FROM LOCAL STORAGE 3) AND NAVIGATING BACK TO LOGIN

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");

    navigate("/login");
  };

  const handleDelete = () => {
    setDeleteMessage("Are you sure?");
  };

  const handleChangeInfo = () => {
    navigate("/create");
  };

  const handleYesDelete = async () => {
    try {
      await axios.delete(`${SERVER_ADDRESS}/api/user/${user.username}`, {
        withCredentials: true,
      });

      alert("Deleted account!");
      handleLogout();
    } catch (error) {
      alert("There was an error deleting your account.");
    }
  };

  const handleNoDelete = () => {
    setDeleteMessage("");
  };

  return (
    <div className="centered-myaccount-container">
      <Card
        className="myAccountCard"
        sx={{
          minWidth: 500,
          minHeight: 450,
          borderRadius: "20px",
          border: "2px solid black",
          paddingRight: 10,
          paddingLeft: 5,
          paddingTop: 2,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            alignItems="top"
            justifyContent="space-between"
            marginBottom="24px"
          >
            {/* Icon of the controller */}
            <img
              src={gameController}
              alt="Game Controller"
              style={{ width: 59, height: 43, marginRight: 15 }}
            />

            {/* Sets up font for Welcome back */}
            <Typography
              sx={{
                fontSize: "36px",
                color: "#3000ff",
                fontWeight: "bold",
                fontFamily: "Open Sans",
              }}
            >
              {user.firstName}'s Account Info
            </Typography>
          </Box>
          <Box>
            <table>
              <tr>
                <td
                  style={{
                    width: "100px",
                    fontFamily: "Open Sans",
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Name:{" "}
                </td>
                <td
                  style={{
                    width: "200px",
                    fontFamily: "Open Sans",
                    color: "#3000FF",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {user.firstName} {user.lastName}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "100px",
                    fontFamily: "Open Sans",
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Email:
                </td>
                <td
                  style={{
                    width: "200px",
                    fontFamily: "Open Sans",
                    color: "#3000FF",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {user.username}
                </td>
              </tr>
            </table>
          </Box>
          <Box>
            <div>
              <Button
                sx={{
                  backgroundColor: "#3000FF",
                  color: "white",
                  marginTop: "20px",
                  marginRight: "10px",
                }}
                variant="contained"
                onClick={handleLogout}
              >
                Logout
              </Button>
              <Button
                sx={{
                  backgroundColor: "#3000FF",
                  color: "white",
                  marginTop: "20px",
                  marginRight: "10px",
                }}
                variant="contained"
                onClick={handleDelete}
              >
                Delete Account
              </Button>
            </div>
          </Box>
          <Box>
            <div>
              {deleteMessage && (
                <Box marginTop={2}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "red",
                      fontWeight: "bold",
                      fontFamily: "Open Sans",
                    }}
                  >
                    {deleteMessage}
                  </Typography>
                  <Box marginTop={2}>
                    {/* Show buttons only when deleteMessage is not empty */}
                    <Button
                      variant="contained"
                      onClick={handleYesDelete}
                      sx={{
                        backgroundColor: "#3000FF",
                        color: "white",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNoDelete}
                      sx={{
                        backgroundColor: "#3000FF",
                        color: "white",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      No
                    </Button>
                  </Box>
                </Box>
              )}
              <Box marginTop={2}>
                <Button
                  variant="contained"
                  onClick={handleChangeInfo}
                  sx={{
                    backgroundColor: "#3000FF",
                    color: "white",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  Change user info
                </Button>
              </Box>
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default MyAccount;
